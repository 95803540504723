
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  created() {
    document.title = "Nykram | Portfolio";
  },
  components: {
    Navbar,
  },
};
