import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "right-panel" }
const _hoisted_2 = {
  target: "_blank",
  href: "https://github.com/xNykram"
}
const _hoisted_3 = {
  target: "_blank",
  href: "https://www.linkedin.com/in/nykram/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", { src: _imports_0 })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/resume" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Resume")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/projects" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Projects")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/contact" }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Contact")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_component_v_icon, {
            scale: "1.2",
            name: "bi-github"
          })
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("a", _hoisted_3, [
          _createVNode(_component_v_icon, {
            scale: "1.2",
            name: "bi-linkedin"
          })
        ])
      ])
    ])
  ]))
}